// Here you can add other styles
textarea {
  resize: none;
}

.pictureHolder {
  display: flex;
  align-items: center;
}

.pictureHolderMax {
  display: flex;
  align-items: center;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 100%;
  height: auto;
}

.justify-content-center .mini-preview-area {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  border: 1px solid !important;
  border-color: #636f83 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-preview-area, .pictureHolder {
  width: 50px !important;
}

.carousel-inner-w-multi-select {
  overflow-y: visible;
}
